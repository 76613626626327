import { Action, action} from 'easy-peasy';

export interface AppointmentsModel {
    bookedAppointments: any;
    selectedProvider: string;
    setSelectedProvider: Action<AppointmentsModel, string>;
    setBookedAppointments: Action<AppointmentsModel, any>;
    clearAppointmentData: Action<AppointmentsModel, string>;
};

export const appointmentsModel: AppointmentsModel = {
    bookedAppointments: window?.localStorage?.bookedAppointment ? JSON.parse(window?.localStorage?.bookedAppointment) : {},
    selectedProvider: '',
    
    setSelectedProvider: action((state, payload) => {
        state.selectedProvider = payload;
    }),

    setBookedAppointments: action((state, payload) => {
        state.bookedAppointments = payload;
    }),

    clearAppointmentData: action((state) => {
        state.bookedAppointments = {}
    }),
};