import { Router } from './routes'; 
import { StoreProvider } from 'easy-peasy';
import store from './store';
import { ApolloProvider } from '@apollo/client';
import client from './api';
import './styles.css';

function App() {
  return (
    <ApolloProvider client={client}>
      <StoreProvider store={store}>
        <Router />
      </StoreProvider>
    </ApolloProvider>
  );
}

export default App;
