import { Heading, Text } from '../Typography';

const headerItems = [
    {
        title: 'Contact',
        type: 'action',
        action: 'mailto:jeffrey.laprade@gmail.com'
    },
    {
        title: 'Last Updated: ',
        date: 'February 12th, 2024'
    },
]

export function Footer(){
    return(
        <div className="h-14 shadow-md w-full bg-white px-8 py-4">
            <div className="flex justify-between">
                {
                    headerItems.map((item, index) => {
                        if(item.date){
                            return (
                                <div className="hover:opacity-50 duration-400 transition-hover cursor-pointer mr-3" key={index}>
                                    <Text>{item.title}<b>{item.date}</b></Text>
                                </div>
                            )
                        }
                        return (
                            <div onClick={()=>window.open(item.action)} className="hover:opacity-50 duration-400 transition-hover cursor-pointer mr-3" key={index}>
                                <Text>{item.title}</Text>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}