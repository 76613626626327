const schoolInfo = [
    {
        city: "CU2",
        description: "Post Baccalaureate Bachelor's of Science in Computer Science",
        title: "University of Colorado Boulder",
        date: "Jan 2018-June 2019",
        image: 'https://jela-website.s3.us-east-2.amazonaws.com/Boulder.jpg',
        courses: [
            "Completed Coursework: ",
            "-Introduction to Programming",
            "-Information Visualization",
            "-Data Science Algorithms",
            "-Algorithms",
            "-Computer System",
            "-Principles of Programming Languages",
            "-Data Systems",
            "-Cognitive Science",
            "-Data Mining",
        ],
        url: "https://www.colorado.edu/academics/postbaccalaureate-bs-applied-computer-science",
        key: 1
    },
    {
        city: "CU1",
        description: "Continuing Education",
        title: "University of Colorado Boulder",
        date: "Fall 2017",
        image: 'https://jela-website.s3.us-east-2.amazonaws.com/Boulder-2.jpeg',
        courses: [
            "Completed Coursework:",
            "-Data Structures",
            "-Discrete Structures" 
        ],
        url: "https://ce.colorado.edu/?utm_source=google%20paid&utm_medium=cpc&utm_campaign=SRCH-OYJ-BD-General&utm_content=continuing-extact&utm_term=university%20of%20colorado%20boulder%20continuing%20education&gad_source=1&gclid=CjwKCAiAt5euBhB9EiwAdkXWO6buHOOFep7IHYnj1bUgVmTNRwZJdrcECkYZ2h0kYwPIikBy9YAuRxoCnlkQAvD_BwE",
        key: 2,
    },
    {
        city: "Denver",
        image: 'https://jela-website.s3.us-east-2.amazonaws.com/Denver.jpg',
        description: "Bachelor's of Science in Business Administration",
        title: "University of Denver",
        date: "2013-2017",
        courses: [
            "Major: Finance",
            "Minor: Spanish",
            "Completed Coursework:",
            "-Business Analytics and Information",
            "-Financial Statements" ,
            "-Accounting" ,
            "-Marketing" ,
            "-Management"
        ],
        url: "https://www.du.edu/",
        key: 4
    },
    {
        city: "Mendoza",
        description: "Immersive Study Abroad Program",
        title: "Universidad de Congreso",
        date: "2015-2016",
        image: 'https://jela-website.s3.us-east-2.amazonaws.com/Mendoza.jpg',
        courses: [
            "Completed Coursework:",
            "-Psychology",
            "-Ecology", 
            "-Financial Mathematics",
            "-Argentine Literature"
        ],
        url: "https://www.google.com/maps/place/Universidad+de+Congreso/@-32.894923,-68.8442479,17z/data=!3m1!4b1!4m6!3m5!1s0x967e0910d9643afb:0x9cabb47f87382cd7!8m2!3d-32.894923!4d-68.841673!16s%2Fg%2F122h0j0j?hl=es_MX&entry=ttu",
        key: 3,
    },
];

export const experienceInfo = [
    {
        title: "Technologies",
        display: [
            { title: "React:", description: "5 years of experience" },
            { title: "Redux:", description: "5 years: Thunk 2 years of experience (easy-peasy), Sagas 3 years of experience" },
            { title: "TypeScript:", description: "3 years of experience"},
            { title: "JavaScript:", description: "5 years of experience" }, 
            { title: "HTML:", description: "5 years of experience" },
            { title: "CSS:", description: "5 years of experience" }, 
        ]
    },
    {
        title: "Experience",
        display: [
            {
                title: 'Front End  Developer',
                display: 'TIFIN: June 2021-Dec 2023',
                body: "Role: First Frontend developer for TIFIN, an incubator for fintech startups. As the first Frontend developer, I have created standards for starting new frontend projects, and I have started multiple new Frontend products from start to finish."
            },
            {
                title: 'Front End Application Developer',
                display: 'Zayo Group: April 2020-June 2021',
                body: "Role: Lead developer for zayo.com, maintainer for zayo's private component library."
            },
            {
                title: 'Jr. Front End Application Developer',
                display: 'Zayo Group: May 2019-April 2020',
            },
            {
                title: 'Ski Instructor at Beaver Creek',
                display: 'Led semi-private group lessons for children/teenagers during the 2018-2019 ski season',
            }
        ]
    },
    {
        title: "Projects",
        display: [
            {
                title: 'Projects led from start to Finish: TIFIN Wealth, Distill AI/AMP',
                display: "Bootstrapped two out of the three major projects/revenue drivers at TIFIN: TIFIN Wealth and AMP (Asset Manager Platform) both of which use React TypeScript applications."
            },
            {
                title: 'Rebuild of state management and transition to TypeScript',
                display: "In the projects listed above, I took it upon myself to refactor those repos to improve the technology and overall efficiency in building them. I initiated and finished a rebuild of state management from Sagas to Thunk as well as transitioned those projects from JS to TS."
            },
            {
                title: 'Collaborated with International Teams in India and Spain',
                display: "Demonstrated versatility in collaborating with diverse teams by effectively wearing multiple hats in various global environments. Successfully engaged with teams in India, implementing diverse React repositories, and collaborated seamlessly in the Spanish language with teams in Spain. Proficiently managed challenges associated with time zone differences and potential communication gaps arising from language or cultural nuances, ensuring smooth and efficient collaboration."
            },
            {
                title: 'Zayo.com',
                display: "Led the rebuild of zayo's website (which had been set up in wordpress) over a three month period and led biweekly deployments."
            },
            {
                title: 'Zayo Private Component Library',
                display: 'Maintainer for the component library repo. The component library was built using Storybook, and I handled Merge requests coming into this repo and contribute myself.'
            },
            {
                title: 'Internal Contact Merge App',
                display: 'Created a React app for merging contacts from our client database. I created a UI that allows users to change/update contacts, and I used Express to send data to and from our API.'
            }
        ]
    }
];

export default schoolInfo;