import { ReactNode } from 'react';
import clsx from 'clsx';

interface TextProps {
    children: ReactNode;
    color?: 'white' | 'light' | 'dark';
    align?: 'center' | 'left';
    size?: "sm" | "md" | "lg" | 'xl';
    margin?: string;
    style?: string;
}

export function Text({
    children, 
    color = 'light',
    align = 'left',
    margin = '',
    style = '',
    size = "md",
}: TextProps){
    return(
        <p className={clsx(
            `w-fit text-sm font-sans ${margin} ${style}`,
            size === 'lg' ? 'text-[18px]' : size === 'md' ? 'text-[16px]' : size === 'xl' ? 'text-[24px]' : 'text-[12px]',
            {'mx-auto': align === 'center'},
            {'font-medium': size === 'xl'},
            {"text-font-white": color === 'white'},
            {"text-font-dark": color === 'dark'},
            {"text-font-light": color === 'light'}
        )}>
            {children}
        </p>
    )
}