import clsx from 'clsx';

interface HeadingProps {
    title: string | number;
    color?: 'white' | 'light' | 'dark';
    size?: 'sm' | 'md' | 'lg' | 'xl';
    align?: 'left' | 'center' | 'right';
    margin?: string;
};

export function Heading({
    title, 
    color = 'dark',
    size = 'xl',
    align = 'center',
    margin = ''
}: HeadingProps){
    return(
        <h2 className={clsx(
            `w-fit mb-6 font-bold font-sans ${margin}`,
            size === 'lg' ? 'text-[32px]' : size === 'md' ? 'text-[24px]' : size === 'sm' ? 'text-[18px]' : 'text-[38px]',
            align === 'center' ? 'mx-auto' : 'mx-0',
            {"text-font-white": color === 'white'},
            {"text-font-dark": color === 'dark'},
            {"text-font-light": color === 'light'}
        )}>
            {title}
        </h2>
    )
}