import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { HomePage } from '../features/HomePage/HomePage';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
// import { Podcasts } from '../features/Podcasts';

export function Router(){
    return(
        <BrowserRouter basename="/">
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                {/* <Route path="academics" element={<Academics />} /> */}
                {/* <Route path="podcasts" element={<Podcasts />} /> */}
                <Route path="*" element={<HomePage />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}