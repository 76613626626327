import { Heading, Text } from '../Typography';
import { useNavigate } from 'react-router-dom';

const headerItems = [
    {
        title: 'Home',
        link: '/home'
    },
    {
        title: 'LinkedIn',
        link: '/linkedIn'
    },
    {
        title: 'Extras',
        link: '/extras'
    }
]

export function Header(){
    const navigate = useNavigate();
    return(
        <div className="position fixed z-10 h-28 shadow-md w-full bg-white px-8 py-4">
            <div>
                <div 
                    onClick={()=>window.open("https://www.linkedin.com/in/lapradejeff/", "_blank")}
                    className="hover:opacity-70 transition-hover duration-500 cursor-pointer"
                >
                    <Heading title="Jeff LaPrade" align="left" margin="mb-0" />
                </div>
                {/* <div className="flex -mt-4">
                    {
                        headerItems.map((item, index) => {
                            return (
                                <div onClick={()=>navigate(item.link)} className="hover:opacity-50 duration-400 transition-hover cursor-pointer mr-3" key={index}>
                                    <Text>{item.title}</Text>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>
        </div>
    )
}