import clsx from 'clsx';
import { Button } from '../../components/Button';
import { Heading, Text } from '../../components/Typography';
import { Background } from  '../BackgroundTable';
import schoolInfo from '../../constants/';

export function HomePage(){
    return (
        <div className="z-9 h-full w-full bg-blue-50 bg-opacity-90">
            <div className="mx-auto px-10"> 
                <div className="pt-40 pb-4">
                    <Background />
                </div>
                <div className="pb-8">
                    <div>  
                        <Heading title="Academics" />
                        <div className="mx-auto w-fit">
                            {
                                schoolInfo.map((location, index) => {
                                    return(
                                    <div 
                                        key={index} 
                                        className={clsx(
                                            "flex justify-start mr-36 my-4 cursor-pointer",
                                        )}
                                    >
                                        <img                                 
                                            onClick={()=>window.open(location.url, "_blank")} 
                                            className="w-[350px] object-cover rounded-lg hover:opacity-70 transition-opacity duration-500" 
                                            src={location.image} 
                                            alt={location.city} 
                                        />
                                        <div>
                                            <Heading size="md" title={location.title} margin="ml-4 mb-0" />
                                            <Text size="md" margin="ml-4">{location.date}</Text>
                                            <Text size="md" margin="ml-4 mb-1">{location.description}</Text>
                                            {location.courses.map((line, index) => {
                                                return <Text key={index} size="sm" margin="ml-4">{line}</Text>
                                            })}
                                        </div>                                    
                                    </div>
                                    )
                                })
                            }    
                        </div>
                    </div>
                </div>
                {/* <div className="mt-8 pb-4">
                    <Heading title="Extras" />
                    <div className="flex justify-between w-fit mx-auto my-4">
                        <Button onClick={()=>console.log('clicked')}>Check out my favorite podcasts</Button>
                        <a className="ml-4" href='https://github.com/jlaprade33/personal-react/tree/dev' target="_blank" style={{textDecoration: 'none', color:"black"}}>
                            <Button onClick={()=>console.log('clicked')}>Check out the repo for this website </Button>
                        </a>
                    </div>
                </div> */}
            </div> 
        </div>
    )
};