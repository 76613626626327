import { createStore, persist, useStoreActions, useStoreState } from 'easy-peasy';
import { appointmentsModel } from '../model/Appointments';
import { userModel } from '../model/User';

const storeModel = {
    appointments: appointmentsModel,
    user: userModel
};

const store = createStore(persist(storeModel));

export const appState = useStoreState;
export const appActions = useStoreActions;

export default store;