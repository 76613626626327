import { Action, action } from 'easy-peasy';

export interface UserModel {
    firstName: string;
    lastName: string;
    dob: any;
    setFirstName: Action<UserModel, string>;
    setLastName: Action<UserModel, string>;
    setDob: Action<UserModel, string>;
    clearUserData: Action<UserModel, string>;
};

export const userModel: UserModel = {
    // Would circle back to improve here if given more time
    firstName: window.localStorage?.firstName?.replace(/["']/g, '') ?? "",
    lastName: window.localStorage?.lastName?.replace(/["']/g, '') ?? "",
    dob: window.localStorage?.dob?.replace(/["']/g, '') ?? "",

    setFirstName: action((state, payload) => {
        state.firstName = payload;
    }),

    setLastName: action((state, payload) => {
        state.lastName = payload;
    }),

    setDob: action((state, payload) => {
        state.dob = payload;
    }),

    clearUserData: action((state, payload) => {
        state.dob = '';
        state.firstName = '';
        state.lastName = '';
    }),
};